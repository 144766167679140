<template>
    <div class="align-items-start p-3 text-start">
        <nav aria-label="breadcrumb">
        <ol class="breadcrumb text-dark m-auto">
            <li class="breadcrumb-item active" aria-current="page">客服管理</li>
            <li class="breadcrumb-item active" aria-current="page">績效管理</li>
        </ol>
        </nav>
        <hr class="w-100">
        <div class="col-12 row mb-3 mx-auto">
            <div class="col-12 accordion" id="accordion_filter">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="heading_filter">
                        <button class="accordion-button bg-dark text-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_filter" aria-expanded="false" aria-controls="collapse_filter">
                            搜尋條件
                        </button>
                    </h2>
                    <div id="collapse_filter" class="accordion-collapse show" aria-labelledby="heading_filter" data-bs-parent="#accordion_filter">
                        <div class="accordion-body col-12 row">
                            <div class="mb-3 col-lg-4">
                                <label for="problem_type" class="form-label">問題類型</label>
                                <select id="problem_type" class="form-select" v-model="query_data.problem_type" @change="onChangeProblemType($event.target.value)">
                                    <option value="">全部</option>
                                    <template v-for="item in problem_type_option" :key="item">
                                        <option :value="item.value">{{ item.text }}</option>
                                    </template>
                                </select>
                            </div>
                            <div class="mb-3 col-lg-4">
                                <label for="problem_subtype" class="form-label">問題子類型</label>
                                <select id="problem_subtype" class="form-select" v-model="query_data.problem_subtype">
                                    <option value="">全部</option>
                                    <template v-for="item in problem_subtype_option" :key="item">
                                        <option :value="item.value">{{ item.text }}</option>
                                    </template>
                                    <option value="尚未分類" v-if="problem_subtype_option.length !== 0">尚未分類</option>
                                </select>
                            </div>
                            <div class="mb-3 col-lg-4">
                                <label for="inbound_type" class="form-label">進線方式</label>
                                <select id="inbound_type" class="form-select" v-model="query_data.inbound_type">
                                    <option value="">全部</option>
                                    <option :value="0">電話進線(東森)</option>
                                    <option :value="1">電話進線(雷門)</option>
                                    <option :value="2">臺中購物節APP留言</option>
                                    <option :value="3">臺中購物節WEB留言</option>
                                    <option :value="4">臺中e指通WEB留言</option>
                                    <option :value="5">社群軟體、APP商店評論留言區</option>
                                    <option :value="6">其他</option>
                                </select>
                            </div>
                            <div class="mb-3 col-lg-4">
                                <label for="status" class="form-label">狀態</label>
                                <select id="status" class="form-select" v-model="query_data.status">
                                    <option value="">全部</option>
                                    <option :value="0">未回覆</option>
                                    <option :value="1">處理中</option>
                                    <option :value="2">轉派</option>
                                    <option :value="3">待議件</option>
                                    <option :value="4">結案</option>
                                </select>
                            </div>
                            <div class="mb-3 col-lg-4">
                                <label for="start_date" class="form-label">建立時間起始</label>
                                <input class="form-control" id="start_date" type="date" v-model="query_data.create_time_from" min="2022-01-01" :max="today"/>
                            </div>
                            <div class="mb-3 col-lg-4">
                                <label for="end_date" class="form-label">建立時間結束</label>
                                <input class="form-control" id="end_date" type="date" v-model="query_data.create_time_to" min="2022-01-01" :max="today"/>
                            </div>
                        </div>
                        <div class="col-12 row text-end mx-auto mb-3">
                            <div>
                                <button class="btn m-2 btn-primary" v-on:click="search()">搜尋</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
        <div class="col-12 row text-end mx-auto mb-3" v-if="role_list.service_ranking.role.split(',')[0] === '1' || role_list.service_ranking.role.split(',')[4] === '1'">
            <div>
                <button class="btn m-2 btn-secondary" v-if="role_list.service_ranking.role.split(',')[4] === '1'" v-on:click="export_data()">匯出</button>
            </div>
        </div>
        <div class="col-12 row justify-content-center mx-auto">
            <div class="col-12 row mb-3  px-0">
                <div class="card" style="border: 0; background-color: transparent;">
                    <div class="card-body" style="background-color: #fff; border: 1px solid rgba(0, 0, 0, 0.125); border-radius: 0.25rem;">
                        <div id="bar_chart" style="width: 100%; height: 400px">
                        </div>
                    </div>
                </div>
            </div>
            <!-- Not Select Problem Type Begin -->
            <div class="col-12 row mb-3 px-0" v-if="export_query_data.problem_type === ''">
                <div class="card" style="border: 0; background-color: transparent;">
                    <div class="card-body" style="background-color: #fff; border: 1px solid rgba(0, 0, 0, 0.125); border-radius: 0.25rem;">
                        <div id="pie_chart" style="width: 100%; height: 400px">
                        </div>
                    </div>
                </div>
            </div>
            <!-- Not Select Problem Type End -->
            <div class="row justify-content-center">
                <div class="col-12 col-lg-6 mb-3 px-0 mx-auto" v-if="export_query_data.problem_type !== ''">
                    <div class="card" style="border: 0; background-color: transparent;">
                        <div class="card-body" style="background-color: #fff; border: 1px solid rgba(0, 0, 0, 0.125); border-radius: 0.25rem;">
                            <div id="pie_chart_2" style="width: 100%; height: 400px">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 mb-3 px-0 mx-auto" v-if="export_query_data.problem_type !== ''">
                    <div class="card" style="border: 0; background-color: transparent;">
                        <div class="card-body" style="background-color: #fff; border: 1px solid rgba(0, 0, 0, 0.125); border-radius: 0.25rem;">
                            <div id="pie_chart" style="width: 100%; height: 400px">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 row mx-auto mb-3">
            <div class="table-responsive container-lg col-12">
                <table class="table table-striped table-hover text-center align-middle">
                    <thead class="table-dark">
                        <tr>
                            <th>日期區間</th>
                            <th v-for="item in problem_type_list" :key="item">
                                {{ item }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in table_data" :key="item">
                            <td v-for="item_data in item" :key="item_data">
                                {{ item_data }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="mb-3"></div>
    </div>
</template>

<script>
import {
    get_performance_data,
    export_performance_data
} from '../../api/performance';
import { mapActions, mapGetters } from 'vuex';
import {
    get_problem_type_option,
    get_problem_subtype_option
} from '../../api/data';
import * as echarts from 'echarts';
import FileSaver from 'file-saver';
import ExcelJS from 'exceljs';
import moment from 'moment';
import Swal from 'sweetalert2';

export default {
    components: {
    },
    data() {
        return {
            problem_type_option: [],
            problem_subtype_option: [],
            today: moment(new Date()).format('YYYY-MM-DD').toString(),
            table_data: [],
            problem_type_list: [],
            query_data: {
                inbound_type: "",
                problem_type: "",
                problem_subtype: "",
                status: "",
                create_time_from: moment(new Date()).format('YYYY-MM-DD').toString(),
                create_time_to: moment(new Date()).format('YYYY-MM-DD').toString(),
            },
            export_query_data: {
                inbound_type: "",
                problem_type: "",
                problem_subtype: "",
                status: "",
                create_time_from: null,
                create_time_to: null,
            },
            bar_chart_option: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {},
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '6%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    name: '案件數量',
                    nameLocation: 'middle',
                    axisLabel: {
                        formatter: "{value} 件"
                    },
                    nameTextStyle: {
                        padding: [15, 0, 0, 0],
                    }
                },
                yAxis: {
                    type: 'category',
                    name: '問題類型',
                    data: []
                },
                series: []
            },
            pie_chart_option: {
                title: {
                    text: '問題類型',
                    left: 'center',
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{b} <br/>{c} 件 ({d}%)'
                },
                legend: {
                    top: '7%',
                },
                series: [
                    {
                        type: 'pie',
                        radius: '50%',
                        data: [],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            },
                        label: {
                            formatter: '{b} {c} 件 ({d}%)',
                        },
                    },
                ]
            },
            pie_chart_option_2: {
                title: {
                    text: '案件狀態',
                    left: 'center',
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{b} <br/>{c} 件 ({d}%)'
                },
                legend: {
                    top: '7%',
                },
                series: [
                    {
                        type: 'pie',
                        radius: '50%',
                        data: [],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            },
                        label: {
                            formatter: '{b} {c} 件 ({d}%)',
                        },
                    },
                ]
            }
        }
    },
    methods: {
        ...mapActions([
            'auth/UserLogout',
        ]),
        user_logout() {
            this['auth/UserLogout']().then(() => {
                this.$router.push({ name: 'Login' });
            }, () => {
                this.$router.push({ name: 'Login' });
            })
        },
        onChangeProblemType(problem_type, init = false) {
            if (init === false) {
                this.query_data.problem_subtype = "";
            }
            get_problem_subtype_option({problem_type: problem_type}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else {
                    this.problem_subtype_option = response.data.response_data["TW"];
                }
            })
        },
        draw_chart() {
            let check_bar_chart_dom = document.getElementById('bar_chart');
            let check_pie_chart_dom = document.getElementById('pie_chart');
            if (check_bar_chart_dom != null && check_bar_chart_dom != "" && check_bar_chart_dom != undefined) {
                echarts.dispose(check_bar_chart_dom);
                let bar_chart_dom = echarts.init(document.getElementById('bar_chart'));
                bar_chart_dom.clear();
                bar_chart_dom.setOption(this.bar_chart_option);
            }
            if (check_pie_chart_dom != null && check_pie_chart_dom != "" && check_pie_chart_dom != undefined) {
                echarts.dispose(check_pie_chart_dom);
                let pie_chart_dom = echarts.init(document.getElementById('pie_chart'));
                pie_chart_dom.clear();
                pie_chart_dom.setOption(this.pie_chart_option);
            }
            let check_pie_chart_2_dom = document.getElementById('pie_chart_2');
            if (check_pie_chart_2_dom != null && check_pie_chart_2_dom != "" && check_pie_chart_2_dom != undefined) {
                echarts.dispose(check_pie_chart_2_dom);
                let pie_chart_2_dom = echarts.init(document.getElementById('pie_chart_2'));
                pie_chart_2_dom.clear();
                pie_chart_2_dom.setOption(this.pie_chart_option_2);
            }
        },
        resize_handler(e) {
            let check_bar_chart_dom = document.getElementById('bar_chart');
            let check_pie_chart_dom = document.getElementById('pie_chart');
            let check_pie_chart_2_dom = document.getElementById('pie_chart_2');
            window.onresize = function() {
                if (check_bar_chart_dom != null && check_bar_chart_dom != "" && check_bar_chart_dom != undefined) {
                    let bar_chart_dom = echarts.init(document.getElementById('bar_chart'));
                    bar_chart_dom.resize();
                }
                if (check_pie_chart_dom != null && check_pie_chart_dom != "" && check_pie_chart_dom != undefined) {
                    let pie_chart_dom = echarts.init(document.getElementById('pie_chart'));
                    pie_chart_dom.resize();
                }
                if (check_pie_chart_2_dom != null && check_pie_chart_2_dom != "" && check_pie_chart_2_dom != undefined) {
                    let pie_chart_2_dom = echarts.init(document.getElementById('pie_chart_2'));
                    pie_chart_2_dom.resize();
                }
            };
        },
        search() {
            if (this.query_data.create_time_from !== null && this.query_data.create_time_from !== ""
                && this.query_data.create_time_to !== null && this.query_data.create_time_to !== "") {
                if (this.query_data.create_time_from > this.query_data.create_time_to) {
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            cancelButton: 'm-2 btn btn-danger'
                        },
                        buttonsStyling: false
                    })
                    swalWithBootstrapButtons.fire({
                        title: '錯誤訊息',
                        text: '起始日期晚於結束日期！',
                        icon: 'error',
                        cancelButtonText: '關閉',
                        showCancelButton: true,
                        showConfirmButton: false,
                        showCloseButton: true,
                        allowOutsideClick: false,
                    })
                    return false;
                }
            }
            this.export_query_data = JSON.parse(JSON.stringify(this.query_data));
            get_performance_data({query_data: this.query_data}).then(response => {
                this.bar_chart_option.yAxis.data = response.data.bar_problem_type;
                this.bar_chart_option.series = [];
                for (let i = 0; i < response.data.case_status.length; i++) {
                    this.bar_chart_option.series[i] = 
                    {
                        name: response.data.case_status[i],
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: response.data.bar_data[response.data.case_status[i]]
                    }
                }
                this.pie_chart_option.series[0].data = [];
                this.pie_chart_option.series[0].data = response.data.pie_data;
                this.pie_chart_option_2.series[0].data = [];
                if (this.export_query_data.problem_type !== "") {
                    this.bar_chart_option.yAxis.name = "子問題類型";
                    this.pie_chart_option.title.text = "子問題類型";
                    this.pie_chart_option_2.series[0].data = response.data.pie_data_2;
                } else {
                    this.bar_chart_option.yAxis.name = "問題類型";
                    this.pie_chart_option.title.text = "問題類型";
                }
                let pie_chart_title_text = "問題類型";
                if (this.export_query_data.problem_type !== "") {
                    pie_chart_title_text = "問題子類型";
                } else {
                    pie_chart_title_text = "問題類型";
                }
                if (response.data.pie_data.length === 0) {
                    this.pie_chart_option.title = {
                        text: pie_chart_title_text  + "\n\n無資料 (No Data)",
                        left: "center",
                        top: "center",
                        textStyle: {
                            fontSize: 30,
                        }
                    };
                } else {
                    this.pie_chart_option.title = {
                        text: pie_chart_title_text,
                        left: 'center',
                    }
                }
                if (response.data.pie_data_2.length === 0) {
                    this.pie_chart_option_2.title = {
                        text: "案件狀態\n\n無資料 (No Data)",
                        left: "center",
                        top: "center",
                        textStyle: {
                            fontSize: 30,
                        }
                    };
                } else {
                    this.pie_chart_option_2.title = {
                        text: '案件狀態',
                        left: 'center',
                    }
                }
                this.table_data = response.data.table_data;
                this.problem_type_list = response.data.problem_type;
                this.draw_chart();
            })
        },
        export_data() {
            export_performance_data({query_data: this.export_query_data}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else if (response.data.success === false && response.data.error_code === "1002") {
                    this.$router.push({ name: 'Index' });
                } else {
                    const data = response.data.response_data;
                    const problem_type_key = response.data.problem_type_key;
                    const problem_subtype_key = response.data.problem_subtype_key;

                    const workbook = new ExcelJS.Workbook()

                    if (this.export_query_data.status !== "") {
                        switch (this.export_query_data.status) {
                            case "0":
                                const sheet_0 = workbook.addWorksheet('未回覆');
                                this.write_execl(sheet_0, data["未回覆"], problem_type_key, problem_subtype_key);
                                break;
                            case "1":
                                const sheet_1 = workbook.addWorksheet('處理中');
                                this.write_execl(sheet_1, data["處理中"], problem_type_key, problem_subtype_key);
                                break;
                            case "2":
                                const sheet_2 = workbook.addWorksheet('轉派');
                                this.write_execl(sheet_2, data["轉派"], problem_type_key, problem_subtype_key);
                                break;
                            case "3":
                                const sheet_3 = workbook.addWorksheet('待議件');
                                this.write_execl(sheet_3, data["待議件"], problem_type_key, problem_subtype_key);
                                break;
                            case "4":
                                const sheet_4 = workbook.addWorksheet('結案');
                                this.write_execl(sheet_4, data["結案"], problem_type_key, problem_subtype_key);
                                break;
                            default:
                                break;
                        }
                    } else {
                        const sheet_all = workbook.addWorksheet('總計');
                        this.write_execl(sheet_all, data["總計"], problem_type_key, problem_subtype_key);
                        const sheet_0 = workbook.addWorksheet('未回覆');
                        this.write_execl(sheet_0, data["未回覆"], problem_type_key, problem_subtype_key);
                        const sheet_1 = workbook.addWorksheet('處理中');
                        this.write_execl(sheet_1, data["處理中"], problem_type_key, problem_subtype_key);
                        const sheet_2 = workbook.addWorksheet('轉派');
                        this.write_execl(sheet_2, data["轉派"], problem_type_key, problem_subtype_key);
                        const sheet_3 = workbook.addWorksheet('待議件');
                        this.write_execl(sheet_3, data["待議件"], problem_type_key, problem_subtype_key);
                        const sheet_4 = workbook.addWorksheet('結案');
                        this.write_execl(sheet_4, data["結案"], problem_type_key, problem_subtype_key);
                    }

                    workbook.xlsx.writeBuffer().then(data => {
                        const blob = new Blob([data], {type: 'application/octet-stream'})
                        FileSaver.saveAs(blob, "績效管理資料明細_" + moment(new Date()).format('YYYYMMDD_HHmmss').toString() +".xlsx")
                    })
                }
            })
        },
        write_execl(sheet, data, problem_type_key, problem_subtype_key) {
            // 預設字體
            const font = { name: 'Microsoft JhengHei', size: 12}

            // 凍結表格
            sheet.views = [
                {state: 'frozen', ySplit: 3}
            ];

            // 預設欄位寬度
            for (let i = 1; i <= 13 ; i++) {
                // 預設欄位寬度
                if (i === 1) {
                    sheet.getColumn(i).width = 30;
                } else {
                    sheet.getColumn(i).width = 15;
                }
                // 預設字體樣式
                sheet.getColumn(i).font = font;
            }

            sheet.getCell('A1').value = "績效管理資料明細"
            sheet.getCell('A1').font = { name: 'Microsoft JhengHei', family: 4, size: 16, bold: true }
            sheet.getCell('L1').value = '製表日期：' + moment(new Date()).format('yyyy/MM/DD').toString()
            sheet.getCell('L1').font = { name: 'Microsoft JhengHei', bold: true }
            sheet.getRow(1).height = 40
            sheet.getRow(2).height = 40
            sheet.getRow(3).height = 40

            // 置中 & 自動換列
            sheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
            sheet.getCell('L1').alignment = { vertical: 'middle', horizontal: 'center' };
            sheet.getRow(2).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            sheet.getRow(3).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

            // 合併表格
            sheet.mergeCells('A1:K1');
            sheet.mergeCells('L1:M1');
            sheet.mergeCells('A2:A3');

            // 填寫預設欄位
            sheet.getRow(2).getCell(1).border = { top: { style: 'medium' }, left: { style: 'medium' },
                bottom: { style: 'medium' }, right: { style: 'medium' } };
            sheet.getRow(2).getCell(1).value = '日期';

            let header_cell_init = 2;
            for (let i = 0; i < problem_type_key.length; i++) {
                let merge_cell_value = "";
                if (header_cell_init / 26 > 1) {
                    merge_cell_value = this.number_to_english(Math.floor(header_cell_init / 26)) + this.number_to_english(header_cell_init % 26) + "2:";
                } else {
                    merge_cell_value = this.number_to_english(header_cell_init % 26) + "2:";
                }
                // 填寫表頭
                sheet.getRow(2).getCell(header_cell_init).border = { top: { style: 'medium' }, left: { style: 'medium' },
                    bottom: { style: 'medium' }, right: { style: 'medium' } };
                sheet.getRow(2).getCell(header_cell_init).value = problem_type_key[i][0];
                for (let j = 0; j < problem_subtype_key[problem_type_key[i]].length; j++) {
                    if (header_cell_init > 13) {
                        // 預設欄位寬度
                        sheet.getColumn(header_cell_init).width = 15;
                        // 預設字體樣式
                        sheet.getColumn(header_cell_init).font = font;
                    }
                    // 填寫表頭
                    sheet.getRow(3).getCell(header_cell_init).border = { top: { style: 'medium' }, left: { style: 'medium' },
                        bottom: { style: 'medium' }, right: { style: 'medium' } };
                    sheet.getRow(3).getCell(header_cell_init).value = problem_subtype_key[problem_type_key[i]][j];
                    header_cell_init += 1;
                }
                if (header_cell_init / 26 > 1) {
                    merge_cell_value = merge_cell_value + this.number_to_english(Math.floor((header_cell_init - 1) / 26)) + this.number_to_english((header_cell_init - 1) % 26) + "2";
                } else {
                    merge_cell_value = merge_cell_value + this.number_to_english((header_cell_init - 1) % 26) + "2";
                }
                // 合併表格
                sheet.mergeCells(merge_cell_value);
            }

            // 填寫資料
            for (let i = 0; i < data.length; i++) {
                sheet.getRow(i + 4).font = { name: 'Microsoft JhengHei'};
                sheet.getRow(i + 4).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

                sheet.getRow(i + 4).getCell(1).value = data[i].week;

                let init_cell = 2;
                for (let j = 0; j < problem_type_key.length; j++) {
                    for (let k = 0; k < problem_subtype_key[problem_type_key[j]].length; k++) {
                        sheet.getRow(i + 4).getCell(init_cell).value = data[i]["problem_type_data"][j][problem_type_key[j]][problem_subtype_key[problem_type_key[j]][k]];
                        init_cell += 1;
                    }
                }
            }
        },
        number_to_english(number) {
            if (number === 1) {
                return "A";
            } else if (number === 2) {
                return "B";
            } else if (number === 3) {
                return "C";
            } else if (number === 4) {
                return "D";
            } else if (number === 5) {
                return "E";
            } else if (number === 6) {
                return "F";
            } else if (number === 7) {
                return "G";
            } else if (number === 8) {
                return "H";
            } else if (number === 9) {
                return "I";
            } else if (number === 10) {
                return "J";
            } else if (number === 11) {
                return "K";
            } else if (number === 12) {
                return "L";
            } else if (number === 13) {
                return "M";
            } else if (number === 14) {
                return "N";
            } else if (number === 15) {
                return "O";
            } else if (number === 16) {
                return "P";
            } else if (number === 17) {
                return "Q";
            } else if (number === 18) {
                return "R";
            } else if (number === 19) {
                return "S";
            } else if (number === 20) {
                return "T";
            } else if (number === 21) {
                return "U";
            } else if (number === 22) {
                return "V";
            } else if (number === 23) {
                return "W";
            } else if (number === 24) {
                return "X";
            } else if (number === 25) {
                return "Y";
            } else if (number === 0) {
                return "Z";
            } else {
                return "";
            }
        }
    },
    created() {
        this.search();
        window.scrollTo({top: 0, behavior: "smooth"});
        window.addEventListener("resize", this.resize_handler);
        get_problem_type_option().then(response => {
            if (response.data.success === false && response.data.check === false) {
                this.user_logout();
            } else {
                this.problem_type_option = response.data.response_data["TW"];
            }
        })
        
    },
    unmounted() {
        window.removeEventListener("resize", this.resize_handler);
    },
    mounted() {
        let check_bar_chart_dom = document.getElementById('bar_chart');
        if (check_bar_chart_dom != null && check_bar_chart_dom != "" && check_bar_chart_dom != undefined) {
            echarts.dispose(check_bar_chart_dom);
        }
        let check_pie_chart_dom = document.getElementById('pie_chart');
        if (check_pie_chart_dom != null && check_pie_chart_dom != "" && check_pie_chart_dom != undefined) {
            echarts.dispose(check_pie_chart_dom);
        }
        let check_pie_chart_2_dom = document.getElementById('pie_chart_2');
        if (check_pie_chart_2_dom != null && check_pie_chart_2_dom != "" && check_pie_chart_2_dom != undefined) {
            echarts.dispose(check_pie_chart_2_dom);
        }
    },
    computed: {
        ...mapGetters({
            role_list: 'role_list'
        }),
    }
};
</script>