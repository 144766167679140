import request from "../utils/request";

export function get_performance_data(data: JSON){
    return request({
        url: "/getPerformanceData",
        method: "post",
        data: data
    });
}

export function export_performance_data(data: JSON){
    return request({
        url: "/exportPerformanceData",
        method: "post",
        data: data
    });
}